import { useEffect, useState } from 'react'
import logo from "../assets/images/logo.png";
import { NavLink,  useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';


const baseUrl = process.env.REACT_APP_BASE_URL
const NewDashboardLayout = ({ children, PageName}) => {
	const [isCollapsed, setIsCollapsed] = useState(false)      
  const [isLicenceSubmenuOpen, setIsLicenceSubmenuOpen] = useState(localStorage.getItem('licencesubmenuOpen') === 'true') 
  const [ isPremisesSubmenuOpen, setIsPremisesSubmenuOpen] = useState(localStorage.getItem('premisesSubmenuOpen') === 'true')
  const [toggleDropdownOpen, setToggleDropdownOpen] = useState(false)
  const [image, setImage] = useState('')
  const [ fullName, setFullName] = useState('')
  const [ LicenceInfo, setLicenceInfo ] = useState({}) 
  const history = useHistory();


	const ToggleCollapesSidebar = () => {
		setIsCollapsed((initState) => !initState)
	}

  const toggleLicenceSubmenu = () => {
    setIsLicenceSubmenuOpen(!isLicenceSubmenuOpen);
  };

  const togglePremiseSubmenu = () => {
    setIsPremisesSubmenuOpen(!isPremisesSubmenuOpen);
  }

  const handleToggleDropdown = () => {
    setToggleDropdownOpen((initState) => !initState)
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    history.push('/login');
  };

  
  useEffect(() => {
    const isAuthenticated = () => {
      return localStorage.getItem('token') !== null;
    };
  
    const AuthenticatedRoute = () => {
      if(!isAuthenticated()){
        Swal.fire({
          title: "Error!",
          text: "Please login before continuing",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          history.push('/login')
        });
      }
    }

    localStorage.setItem('licencesubmenuOpen', isLicenceSubmenuOpen);
    localStorage.setItem('premisesSubmenuOpen', isPremisesSubmenuOpen);
    AuthenticatedRoute()
    getUserDetails()
  }, [history, isLicenceSubmenuOpen, isPremisesSubmenuOpen])



  const getUserDetails = async () => {
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await fetch(
      `${baseUrl}/v2/dashboard/user/${localStorage.getItem(
        "userid"
      )}`,
      obj
    )
    .then((response) => response?.json())
    .then((responseJson) => {
      if (responseJson?.userInformation) {
        const { userInformation, licenseInformation } = responseJson;
        setImage(userInformation?.userImage)
        setFullName(userInformation?.fullName)
        setLicenceInfo(licenseInformation)
      }
    }).catch((error) => {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong, check your internet",
        icon: "error",
        confirmButtonText: "OK",
      })
    })
  };

  return (
    <div className="wrapper ">

      {/*********************  SIDEBAR  *****************************/}
      <nav id="sidebar" className={`sidebar js-sidebar position-fixed z-5 ${isCollapsed && ' collapsed'}`} style={{backgroundColor: "rgb(0,38,75)"}}>
        <div className="sidebar-content  js-simplebar" style={{backgroundColor: "rgb(0,38,75)"}} >
          <div className="sidebar-brand text-center">
            <img
              src={logo}
              className="navbar-brand-img h-50 p-0 m-0"
              alt="main_logo"
            />
            <hr /> 
          </div>

          <ul className="sidebar-nav"  style={{backgroundColor: "rgb(0,38,75)"}}>

            <NavLink to="/new-dashboard" activeClassName="active" className="sidebar-item pb-4">
              <span className="sidebar-link" style={{backgroundColor: "rgb(0,38,75)"}}>
                <i className="align-middle material-icons opacity-10 text-light" data-feather="sliders">dashboard</i> <span className="align-middle">Dashboard</span>
              </span>
            </NavLink>

            <div className="sidebar-item">
              <nav onClick={toggleLicenceSubmenu} className="sidebar-link d-flex justify-content-lg-between" href="#submenuLicence" type="button" style={{backgroundColor: "rgb(0,38,75)"}}>
                <div>
                  <i className="align-middle material-icons opacity-10 text-light" data-feather="user">receipt</i> <span className="align-middle">Licence</span>
                </div>
                <i className="right-icon">
                  { isLicenceSubmenuOpen ? 
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg> :
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                  </svg>
                }  
                </i>
              </nav>
              {isLicenceSubmenuOpen && (
              <ul class="sub-nav my-2 pl-5 pl-lg-7">
                <NavLink activeClassName="active" to="/new-licence" className="sidebar-item pb-4">
                  <span className="sidebar-link" style={{backgroundColor: "rgb(0,38,75)"}}>
                    <span className="align-middle">Apply For Licence</span>
                  </span>
                </NavLink>
              {/*  { !LicenceInfo?.hasLicenseApplication && <NavLink activeClassName="active" to="/new-licence" className="sidebar-item pb-4">
                  <span className="sidebar-link" style={{backgroundColor: "rgb(0,38,75)"}}>
                    <span className="align-middle">Apply For Licence</span>
                  </span>
                </NavLink>*/
                }
           {/*     { LicenceInfo?.hasLicenseApplication && <NavLink activeClassName="active" to="/new-renew-licence" className="sidebar-item pb-4">
                  <span className="sidebar-link" style={{backgroundColor: "rgb(0,38,75)"}}>
                    <span className="align-middle">Renew Licence</span>
                  </span>
                </NavLink>
                }*/}
                <NavLink activeClassName="active" to="/new-licence-history" className="sidebar-item pb-4">
                  <span className="sidebar-link" style={{backgroundColor: "rgb(0,38,75)"}}>
                    <span className="align-middle">Licence History</span>
                  </span>
                </NavLink>
              </ul>
              )}
            </div>

            <div>
              <nav onClick={togglePremiseSubmenu} className="sidebar-link d-flex justify-content-lg-between" href="#submenuLicence" type="button" style={{backgroundColor: "rgb(0,38,75)"}}>
                <div>
                  <i className="align-middle material-icons opacity-10 text-light" data-feather="log-in">store</i> <span className="align-middle">Premises</span>
                </div>
                <i className="right-icon">
                  { isPremisesSubmenuOpen ? 
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg> :
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                  </svg>
                }  
                </i>
              </nav>
              <ul class="sub-nav my-2 pl-5 pl-lg-7">
                { isPremisesSubmenuOpen && (
                  <>
                  <NavLink to="/new-apply-premises-inspection" activeClassName="active" className="sidebar-item pb-4">
                    <span className="sidebar-link" href="pages-sign-in.html" style={{backgroundColor: "rgb(0,38,75)"}}>
                      <span className="align-middle">Apply For Premises Inspection</span>
                    </span>
                  </NavLink>
                  <NavLink to="/new-premises-history" activeClassName="active" className="sidebar-item pb-4">
                  <span className="sidebar-link" href="pages-sign-in.html" style={{backgroundColor: "rgb(0,38,75)"}}>
                    <span className="align-middle">Premises History</span>
                  </span>
                </NavLink>
                </>
                )}
              </ul>
            </div>
 
            <NavLink to="/new-payments" activeClassName="active" className="sidebar-item pb-4">
              <a className="sidebar-link" href="pages-sign-up.html" style={{backgroundColor: "rgb(0,38,75)"}}>
                <i className="align-middle material-icons opacity-10 text-light" data-feather="user-plus">payment</i> <span className="align-middle">Payment</span>
              </a>
            </NavLink>
          </ul>


          <NavLink to="/logout" activeClassName="active" className="sidebar-item pb-4">
            <a className="sidebar-link" href="pages-sign-up.html" style={{backgroundColor: "rgb(0,38,75)"}}>
              <i className="align-middle material-icons opacity-10 text-light" data-feather="user-plus">logout</i> <span className="align-middle">Logout</span>
            </a>
          </NavLink>

        </div>
      </nav>
      {/********************* END SIDEBAR  *****************************/}



      <div className={`${!isCollapsed && 'sidebarToggleWidth' } main`}>	
        {/********************* NAVBAR  *****************************/}
        <nav className="navbar navbar-expand position-fixed z-2 container-fluid navbar-light navbar-bg" style={{width: "-webkit-fill-available"}}>

          <div className="navbar-collapse collapse">
            <ul className="navbar-nav navbar-align">
          
              <li className="nav-item dropdown d-ml-4">

                <div onClick={handleToggleDropdown} className="nav-link dropdown-toggle d-none d-sm-inline-block cursor-pointer"  data-bs-toggle="dropdown">
                  <img src={image} className="avatar img-fluid rounded me-1 border rounded-circle mb-" alt="user" /> <span className="text-dark font-weight-bold">{fullName}</span>
                </div>
                <div className={`${ toggleDropdownOpen ? 'd-block' : 'd-none'} position-absolute top-25 bg-white border-sm shadow-sm py-3 dropdown-menu-end`}>
                  <a className="dropdown-item py-1 px-4 font-weight-bold" href="/help"><i className="align-middle" data-feather="help-circle"></i> Help Center</a>
                  <div className="dropdown-divider"></div>
                  <nav onClick={handleLogout} className="dropdown-item py-1 px-4 font-weight-bold">Log out</nav>
                </div>
              </li>
              <div onClick={ToggleCollapesSidebar} className="sidebar-toggle js-sidebar-toggle pt-3">
                <i className="hamburger align-self-center"></i>
              </div>
            </ul>
          </div>
        </nav>
        {/********************* END NAVBAR  *****************************/}


        <main className="content">
          <div className="container-fluid p-0">
            <h1 className="h3 mb-3 mt-5"><strong>{PageName}</strong> </h1>
          </div>
          {children}
        </main>

        
      </div>
    </div>
  )
}


export default NewDashboardLayout
import React, { Component } from "react";
import { Spinner, Alert, Form } from "react-bootstrap";
 
import "bootstrap/dist/css/bootstrap.min.css";
import { states } from "../components/States";
import { countries } from "../components/Countries";
import Swal from "sweetalert2";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import coat from "../assets/images/coat.png";
import Sidebar from "../components/Sidebar";
import moment from "moment";
let LICENCE_FILEBASE64 = "";
let FORM_ID = "";


const baseUrl = process.env.REACT_APP_BASE_URL
 
class RenewLicence extends Component {
  constructor(props) {
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    super(props);
    this.state = {
      data: [],
      renewData: [],
      foodScientistData: [],
      practiceCategory: [],
      feeType: [],
      title: "",
      tertiaryinstitution: "",
      gender: "",
      surname: "",
      prevSurname: "",
      firstname: "",
      othername: "",
      courseStudy: "",
      dob: "",
      orgPosition: "",
      mailingAddres: "",
      phone: "",
      disabled: false,
      nationality: "",
      categoryPractice: "",
      state: "",
      lga: "",
      boxAll: false,
      address: "",
      password: "",
      confirmPassword: "",
      sponsorName: "",
      sponsorName2: "",
      sponsorNo2: "",
      sponsorNo: "",
      orgName: "",
      orgEmail: "",
      orgPhone: "",
      orgAddress: "",
      qualification: "",
      qualificationYear: "",
      // remitaNo: "",
      fileUpload: "",
      city: "",
      email: "",
      nifst: "",
      countryCode: "",
      colorSurname: "black",
      colorFirstName: "black",
      colorTitle: "black",
      colorEmail: "black",
      colorPractice: "black",
      colorOthername: "black",
      colorNationality: "black",
      colorTetiary: "black",
      colorLga: "black",
      colorPhone: "black",
      colorPassword: "black",
      colorPasswordConf: "black",
      colorSponsor: "black",
      colorSponsorNo: "black",
      colorOrgName: "black",
      colorOrgEmail: "black",
      colorOrgPhone: "black",
      colorAddress: "black",
      // colorCity: "black",
      colorPosition: "black",
      colorState: "black",
      colorGender: "black",
      colorDob: "black",
      colorNIFST: "black",
      colorQualYear: "black",
      // colorRemita: "black",
      colorQual: "black",
      colorMailAddress: "black",
      colorCountryCode: "black",
      colorStudy: "black",
      loading: true,
      isLoading: true,
      isRenewalLoading: false,
      isUserId: false,
      // hideForm: false,
      postsPerPage: 10,
      currentPage: 1,
      fees: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleQualificationChange = this.handleQualificationChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleFeeChange = this.handleFeeChange.bind(this);
    this.handlePracticeChange = this.handlePracticeChange.bind(this);
  }

  showTable = () => {
    const { postsPerPage, currentPage, data } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data
      .reverse()
      .slice(indexOfFirstPost, indexOfLastPost);

    try {
      return currentPosts.map((item, index) => {
        let licenceDate = new Date(item.licensedate);
        let licenceExpDate = new Date(item.licenseexpdate); // Use licenseexpdate instead of a fixed date
        const diffDays = Math.round(
          (licenceExpDate - licenceDate) / (1000 * 60 * 60 * 24) // Calculate the difference in days
        );

        return (
          <tbody key={index}> {/* Add a key to avoid React warnings */}
            <tr>
              <td>
                <span className="text-xs font-weight-bold">
                  {postsPerPage * (currentPage - 1) + index + 1}
                </span>
              </td>
              {/* <td className="text-xs text-secondary text-capitalize font-weight-bold">{`${item.surname} ${item.othernames}`}</td>
              <td className="text-xs font-weight-bold">
                {item.contacttelephone}
              </td>
              <td className="text-xs font-weight-bold">
                {item.practicecategory}
              </td> */}
              <td className="text-xs font-weight-bold">
                {moment(item.licensedate).format("LL") === "Invalid date"
                  ? null
                  : moment(item.licensedate).format("LL")}
              </td>
              <td className="text-xs font-weight-bold">
                {moment(item.licenseexpdate).format("LL")}
              </td>
              <td className="text-xs font-weight-bold">
                <span
                  className={
                    diffDays === 60
                      ? "badge bg-warning text-xs font-weight-bold"
                      : diffDays > 60
                      ? "badge bg-success text-xs font-weight-bold"
                      : "badge bg-danger text-xs text-light font-weight-bold"
                  }
                >
                  {diffDays === 0 ? "Expired" : diffDays + " days remaining"}
                </span>
              </td>
              <td>
                <button
                  className="btn btn-primary-2 mb-0"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span
                    className="iconify"
                    data-icon="charm:menu-meatball"
                    style={{ fontSize: "large" }}
                  ></span>
                </button>
                <ul
                  className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4"
                  aria-labelledby="#dropdownMenuButton2"
                >
                  <li
                    className="mb-2"
                    id={item.formid}
                    onClick={() => this.getUserDetails(item.recid)}
                    data-bs-toggle="modal"
                    data-bs-target="#viewScientist"
                  >
                    <a
                      className="dropdown-item border-radius-md"
                      href="javascript:;"
                    >
                      <div className="d-flex py-1">
                        <h6 className="text-sm font-weight-normal mb-1">
                          <span className="font-weight-bold">View</span>
                        </h6>
                      </div>
                    </a>
                  </li>

                  {postsPerPage * (currentPage - 1) + index + 1 === 1 && (
                    <li
                      className="mb-2"
                      id={item.recid}
                      onClick={() => this.getUserRenewDetails(item.recid)}
                      data-bs-toggle="modal"
                      data-bs-target="#verifyPayments"
                    >
                      <a
                        className="dropdown-item border-radius-md"
                        href="javascript:;"
                      >
                        <div className="d-flex py-1">
                          <h6 className="text-sm font-weight-normal mb-1">
                            <span className="font-weight-bold">Renew</span>
                          </h6>
                        </div>
                      </a>
                    </li>
                  )}
                </ul>
              </td>
              <td></td>
            </tr>
          </tbody>
        );
      });
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: e.message,
        type: "error",
      });
    }
  };

  showPagination = () => {
    const { postsPerPage, data } = this.state;
    const pageNumbers = [];
    const totalPosts = data.length;
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
      pageNumbers.push(i);
    }

    const paginate = (pageNumbers) => {
      this.setState({ currentPage: pageNumbers });
    };

    return (
      <nav>
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={
                this.state.currentPage === number
                  ? "page-item active"
                  : "page-item"
              }
            >
              <button onClick={() => paginate(number)} className="page-link">
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  handleChange(e) {
    this.setState({ title: e.target.value });
  }
  handleStateChange(e) {
    this.setState({ state: e.target.value });
  }

  handleGenderChange(e) {
    this.setState({ gender: e.target.value });
  }

  handleCountryChange(e) {
    this.setState({ countryCode: e.target.value });
  }

  handleQualificationChange(e) {
    this.setState({ qualification: e.target.value });
  }

  handleCheckChange(e) {
    this.setState({ boxAll: e.target.checked }, () => {
      console.log("This returned true or false", this.state.boxAll);
    });
  }

  handleFeeChange(e) {
    this.setState({ fees: e.target.value });
  }

  handlePracticeChange(e) {
    this.setState({ categoryPractice: e.target.value });
  }

  getFeeType = async () => {
    const url = `${baseUrl}/Fees/getFeesByPaymentType/individual`;
    this.setState({ isLoading: true });
    await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.warn(res);
        if (res.status === 401) {
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        } else {
          this.setState({ feeType: res });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
        alert(error);
      });
  };

  getPracticeCategory = async () => {
    const url = `${baseUrl}/Lookups/GetPracticeCategory`;
    await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 401) {
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        } else {
          this.setState({ practiceCategory: res });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
        alert(error);
      });
  };

  showFeeTypes() {
    return this.state.feeType.map((item) => {
      return (
        <option
          disabled={
            item.description === "Membership/Individual Renewal Fee"
              ? ``
              : "disabled"
          }
          value={`${item.paymenttype}, ${item.amount}, ${item.description}, ${item.paymentsubcategory}, ${item.remitaid}`}
        >
          {item.description === "Membership/Individual Renewal Fee"
            ? `${item.description.toUpperCase()} (N${item.amount})`
            : ""}
        </option>
      );
    });
  }

  showPracticeCategory() {
    return this.state.practiceCategory.map((item) => {
      return <option value={item.referencename}>{item.referencename}</option>;
    });
  }

  uploadNIFSTCertificate = async () => {
    this.setState({ loading: false });
    var obj = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        imageToBase64String: LICENCE_FILEBASE64,
        formid: FORM_ID,
      }),
    };
    await fetch(`${baseUrl}/Registration/uploadRegistrationImage`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        if (responseJson.message === "Image Updated Successfully") {
          // FORM_ID = responseJson.formid;
          this.registerScientist();
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  checkValidation = async (e) => {
    const {
      title,
      gender,
      surname,
      firstname,
      othername,
      prevSurname,
      categoryPractice,
      dob,
      address,
      fees,
      tertiaryinstitution,
      qualification,
      qualificationYear,
      courseStudy,
      nationality,
      state,
      nifst,
      phone,
      password,
      confirmPassword,
      mailingAddres,
      sponsorName,
      boxAll,
      sponsorName2,
      sponsorNo,
      sponsorNo2,
      colorPractice,
      lga,
      orgAddress,
      orgPosition,
      orgEmail,
      orgName,
      orgPhone,
      city,
      email,
      countryCode,
      registrationnumber,
    } = this.state;
    this.setState({ loading: true, disabled: true });
    e.preventDefault();

    if (surname === "" && localStorage.getItem("surname") === null) {
      Swal.fire({
        title: "Empty",
        text: "Please enter your surname",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorSurname: "red" });
    } else if (
      othername === "" &&
      localStorage.getItem("othernames") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your other name",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorOthername: "red" });
    } else if (title === "" && localStorage.getItem("title") === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your title",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorTitle: "red" });
    } else if (
      address === "" &&
      localStorage.getItem("contactaddress") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Mailing address",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorMailAddress: "red",
      });
    } else if (dob === "" && localStorage.getItem("DOB") === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Date of birth",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorDob: "red" });
    } else if (
      nationality === "" &&
      localStorage.getItem("nationality") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your nationality",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorNationality: "red",
      });
    } else if (gender === "" && localStorage.getItem("gender") === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your gender",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorGender: "red" });
    } else if (state === "" && localStorage.getItem("state") === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your State of Origin",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorState: "red" });
    } else if (lga === "" && localStorage.getItem("lga") === null) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Local Government Area",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorState: "red" });
    } else if (
      courseStudy === "" &&
      localStorage.getItem("courseofstudy") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your course of study",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorStudy: "red" });
    } else if (
      tertiaryinstitution === "" &&
      localStorage.getItem("tertiaryinstitution") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Tertiary Institution",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorTetiary: "red" });
    } else if (
      nifst === "" &&
      localStorage.getItem("nifstregistrationnumber") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your NIFST number",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorNIFST: "red" });
    } else if (
      qualification === "" &&
      localStorage.getItem("qualification") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Qualification",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorQual: "red" });
    } else if (
      this.state.qualificationYear === "" &&
      localStorage.getItem("yearofqualification") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Year of Qualification",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorQualYear: "red" });
    } else if (
      categoryPractice === "" &&
      localStorage.getItem("practiceCategory") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Practice Category",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorPractice: "red" });
    } else if (countryCode === "" && !localStorage.getItem("token")) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Country Code",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorCountryCode: "red",
      });
    } else if (phone === "" && !localStorage.getItem("token")) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Phone Number",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorPhone: "red" });
    } else if (email === "" && localStorage.getItem("contactemail") === null) {
      Swal.fire({
        title: "Empty",
        text: "Please verify your email",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorEmail: "red" });
    } else if (localStorage.getItem("userid") == "" && password === "") {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Password",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorPassword: "red" });
    } else if (localStorage.getItem("userid") == "" && confirmPassword === "") {
      Swal.fire({
        title: "Empty",
        text: "Please Confirm your Password",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorPasswordConf: "red",
      });
    } else if (
      localStorage.getItem("userid") == "" &&
      confirmPassword !== password
    ) {
      Swal.fire({
        title: "Error",
        text: "Passwords do not match",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorPasswordConf: "red",
        colorPassword: "red",
      });
    } else if (
      mailingAddres === "" &&
      localStorage.getItem("contactaddress") == null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Mailing Address",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorMailAddress: "red",
      });
    } else if (
      orgName === "" &&
      localStorage.getItem("organization") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Organization Name",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorOrgName: "red" });
    } else if (
      orgPosition === "" &&
      !localStorage.getItem("organizationposition")
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your position in your Organization",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorPosition: "red" });
    } else if (
      orgAddress === "" &&
      localStorage.getItem("organizationaddress") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify address of your Organization",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorOrgAddress: "red",
      });
    } else if (
      orgPhone === "" &&
      localStorage.getItem("organizationtelephone") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify phone number of your Organization",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorOrgPhone: "red" });
    } else if (
      orgEmail === "" &&
      localStorage.getItem("organizationemail") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify email of your Organization",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorOrgEmail: "red" });
    } else if (
      sponsorName === "" &&
      localStorage.getItem("sponsorname1").trim().length <= 0
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please supply at least one sponsor",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorSponsor: "red" });
    } else if (
      sponsorNo === "" &&
      localStorage.getItem("sponsorcfsnno1") === null
    ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify the CSFN of Your Sponsor",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorSponsorNo: "red" });
      // } else if (fileUpload === "") {
      //   Swal.fire({
      //     title: "Empty",
      //     text: "Please upload your recent photo",
      //     icon: "error",
      //     confirmButtonText: "OK",
      //   });
      //   this.setState({ loading: false, disabled: false, colorSponsorNo: "red" });
    } else if (boxAll === false) {
      Swal.fire({
        title: "Accept The Terms",
        text: "Please accept the terms and conditions",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false });
    } else {
      this.renewLicence();
    }
  };

  // RENEW LICENCE
  renewLicence = async () => {
    let date = new Date();
    let fees = this.state.fees.split(",");

    console.warn(fees);
    localStorage.setItem("amount", fees[1].trim());
    localStorage.setItem("description", fees[2].trim());
    localStorage.setItem("category", fees[3].trim());
    localStorage.setItem("serviceTypeId", fees[4].trim());

    let obj = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },

      body: JSON.stringify({
        DOB:
          this.state.dob !== "" ? this.state.dob : localStorage.getItem("DOB"),
        RRR: "",
        applicationdaterecieved: `${date.getTime()}`,
        contactaddress:
          this.state.address !== ""
            ? this.state.address
            : localStorage.getItem("contactaddress"),
        contactemail:
          this.state.email !== ""
            ? this.state.email
            : localStorage.getItem("contactemail"),
        contacttelephone:
          this.state.phone !== ""
            ? this.state.phone
            : localStorage.getItem("contacttelephone"),
        formtype: "renewal",
        gender:
          this.state.gender !== ""
            ? this.state.gender
            : localStorage.getItem("gender"),
        lga:
          this.state.lga !== "" ? this.state.lga : localStorage.getItem("lga"),
        nationality:
          this.state.nationality !== ""
            ? this.state.nationality
            : localStorage.getItem("nationality"),
        organization:
          this.state.orgName !== ""
            ? this.state.orgName
            : localStorage.getItem("organisation"),
        organizationaddress:
          this.state.orgAddress !== ""
            ? this.state.orgAddress
            : localStorage.getItem("orgAddress"),
        organizationemail:
          this.state.orgEmail !== ""
            ? this.state.orgEmail
            : localStorage.getItem("organizationemail"),
        organizationposition:
          this.state.orgPosition !== ""
            ? this.state.orgPosition
            : localStorage.getItem("organizationposition"),
        organizationtelephone:
          this.state.orgPhone !== ""
            ? this.state.orgPhone
            : localStorage.getItem("organizationtelephone"),
        othernames:
          this.state.ortherName !== ""
            ? this.state.ortherName
            : localStorage.getItem("othernames"),
        practicecategory:
          this.state.categoryPractice !== ""
            ? this.state.categoryPractice
            : localStorage.getItem("practiceCategory"),
        previouslicensedate: "",
        previouslicensenumber: "",
        previoussurname:
          this.state.prevSurname !== ""
            ? this.state.prevSurname
            : localStorage.getItem("previoussurname"),
        qualification1:
          this.state.qualification !== ""
            ? this.state.qualification
            : localStorage.getItem("qualification"),
        qualification2: "",
        qualification3: "",
        qualification4: "",
        qualification5: "",
        recid: localStorage.getItem("recid"),
        registrationnumber:
          this.state.nifst !== ""
            ? this.state.nifst
            : localStorage.getItem("nifstregistrationnumber"),
        state: this.state.state,
        surname:
          this.state.surname !== ""
            ? this.state.surname
            : localStorage.getItem("surname"),
        title: this.state.title,
        yearofqualification:
          this.state.qualificationYear !== ""
            ? this.state.qualificationYear
            : localStorage.getItem("yearofqualification"),
      }),
    };
    fetch(`${baseUrl}/License/updatelicense`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        if (responseJson.message == "License updated Successfully") {
          this.setState({ loading: false, disabled: false });
          Swal.fire({
            title: "Success",
            text: "License updated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/licence-renewal-payment");
          });
        } else if (responseJson.status == 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  getStates() {
    return states.map((state) => {
      return <option value={state}>{state}</option>;
    });
  }

  getCountry() {
    return countries.map((country) => {
      return <option value={country.dial_code}>{country.name}</option>;
    });
  }

  getLicenceRegistration = async () => {
    const url = `${baseUrl}/License/getlicenseByUserID/${localStorage.getItem(
      "userid"
    )}`;
    this.setState({ isLoading: true });
    await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.warn(res);
        if (res.status === 401) {
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        } else {
          this.setState({ data: res, loading: false });
        }
        // console.warn(res);
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
        alert(error);
      });
  };

  getUserDetails = (recid) => {
    const url = `${baseUrl}/License/getlicenseByRecID/${recid}`;
    this.setState({ isLoading: true });
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.warn(res);
        this.setState({
          isLoading: false,
          foodScientistData: res,
        });
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
        alert(error);
      });
  };

  getUserRenewDetails = (recid) => {
    const url = `${baseUrl}/License/getlicenseByRecID/${recid}`;
    this.setState({ isRenewalLoading: true });
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.warn(res);
        this.setState({
          isRenewalLoading: false,
          renewData: res,
        });
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
        alert(error);
      });
  };

  componentDidMount() {
    this.getLicenceRegistration();
    this.getFeeType();
    this.getPracticeCategory();
  }

  render() {
    const {
      loading,
      isLoading,
      foodScientistData,
      isRenewalLoading,
      renewData,
    } = this.state;
    return (
      <div className="container" style={{ alignSelf: "center" }}>
        {localStorage.getItem("token") && <Sidebar />}
        <div
          className="text-center container-fluid px-4 d-flex justify-content-between"
          style={{
            width: "70%",
            justifyContent: "space-evenly",
            alignItems: "center",
            position: "relative",
            top: 63,
            left: 72,
            marginBottom: 27,
          }}
        >
          <div>
            <img
              src={logo}
              className="navbar-brand-img"
              alt="main_logo"
              style={{ width: 81 }}
            />
          </div>
          <div style={{ position: "relative", left: 45 }}>
            <h4 className="font-weight-bold text-center">
              NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NiCFoST)
            </h4>
          </div>
          <div>
            <img
              src={coat}
              className="navbar-brand-img h-100"
              style={{ width: 126, position: "relative", left: 90 }}
              alt="main_logo"
            />
          </div>
        </div>
        <main
          className="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
          style={{ width: "80%", float: "right" }}
        >
          <div
            className="container-fluid justify-content-center py-4"
            style={{ width: "100%", position: "relative", left: 0 }}
          >
            <div class="container-fluid px-4">
              <div class="rown">
                <div class="col-12">
                  <div class="card my-3">
                    <div class="card-header pb-0">
                      <div class="d-flex flex-wrap align-items-center">
                        <h5 className="mb-4">
                          My Licence Registration History
                        </h5>
                      </div>
                    </div>
                    <div class="card-body">
                      {loading ? (
                        <center>
                          <Spinner
                            animation="border"
                            variant="success"
                            size="lg"
                            style={{ alignSelf: "center" }}
                            className="text-center"
                          />
                        </center>
                      ) : (
                        <div class="table-responsive p-0 pb-2">
                          <table
                            id="table"
                            className="table align-items-center justify-content-center mb-0"
                          >
                            <thead>
                              <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                  S/N
                                </th>
                                {/* <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Name
                                </th>
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Phone
                                </th>
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Practice Categoryi
                                </th> */}
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Approval date
                                </th>
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Expiration date
                                </th>
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Expiration Status
                                </th>
                                <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
                                  Action
                                </th>
                              </tr>
                            </thead>

                            {this.showTable()}
                          </table>
                        </div>
                      )}

                      <div style={{ float: "right" }}>
                        {this.showPagination()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* Start of View Modal */}
        <div
          class="modal fade"
          id="viewScientist"
          tabindex="-1"
          aria-labelledby="viewRunning"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div
                class="modal-header d-flex align-items-center justify-content-between"
                style={{ backgroundColor: "#00264C" }}
              >
                <h5 class="modal-title text-light">View Record</h5>
                <button
                  type="button"
                  class="btn btn-link m-0 p-0 text-light fs-4"
                  data-bs-dismiss="modal"
                  aria-label="Print"
                >
                  <i
                    style={{ fontSize: 27, color: "#fff" }}
                    class="material-icons opacity-10 text-light"
                  >
                    print
                  </i>
                </button>
                <button
                  type="button"
                  class="btn btn-link m-0 p-0 text-light fs-4"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span class="iconify" data-icon="carbon:close"></span>
                </button>
              </div>
              {isLoading ? (
                <center>
                  <Spinner
                    animation="border"
                    style={{ padding: 3 }}
                    variant="success"
                    size="lg"
                  />
                </center>
              ) : (
                <div class="modal-body">
                  {foodScientistData.length > 0 &&
                    foodScientistData.map((item) => {
                      return (
                        <main
                          class="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
                          style={{
                            width: !localStorage.getItem("token")
                              ? "90%"
                              : "100%",
                            position: localStorage.getItem("token")
                              ? "relative"
                              : "",
                            right: 0,
                            padding: 18,
                            float: !localStorage.getItem("token")
                              ? ""
                              : "right",
                            marginBottom: 90,
                          }}
                        >
                          <div className="container-fluid px-4">
                            <div className="rown">
                              <div className="col-12">
                                <div className="card my-3 mb-4">
                                  <div className="card-header pb-0 bg-success">
                                    <div className="text-center">
                                      <h5 className="text-light text-center font-weight-bold mb-4">
                                        Application Form for Licence
                                        Registration
                                      </h5>
                                    </div>
                                  </div>
                                  {/* <div class="card-body"> */}
                                  <div
                                    className="container"
                                    style={{ marginTop: 0, padding: 18 }}
                                  >
                                    <form className="row">
                                      <label
                                        className="mb-3 h4"
                                        style={{ color: "green" }}
                                        htmlFor="floatingInputCustom"
                                      >
                                        Personal Information
                                      </label>
                                      <br />
                                      <br />
                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorSurname,
                                          }}
                                          className="form-label"
                                        >
                                          Surname{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            required="required"
                                            value={item.surname}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label className="form-label text-dark">
                                          Previous Surname (If applicable)
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            value={item.previoussurname}
                                          />
                                        </div>
                                      </div>

                                      {/*
                  <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                    <label
                      style={{ color: this.state.colorFirstName }}
                      className="form-label"
                    >
                      First name <span className="text-danger">*</span>
                    </label>
                    <div className="input-group input-group-outline mb-3">
                      <label className="form-label"></label>
                      <input
                        className="form-control shadow-none"
                        type="text"
                        required="required"
                        defaultValue={localStorage.getItem("firstname")}
                        onChange={(e) => this.setState({ firstname: e.target.value })}
                      />
                    </div>
                  </div> */}

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorOthername,
                                          }}
                                          className="form-label"
                                        >
                                          Other name(s){" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            value={item.othernames}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorOthername,
                                          }}
                                          className="form-label"
                                        >
                                          Title{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            value={item.title}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{ color: this.state.colorDob }}
                                          className="form-label"
                                        >
                                          DOB{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            id="datepicker"
                                            type="date"
                                            value={item.DOB}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          className="form-label"
                                          style={{
                                            color: this.state.colorNationality,
                                          }}
                                        >
                                          Nationality{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            type="text"
                                            className="form-control shadow-none"
                                            value={item.nationality}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          className="form-label"
                                          style={{
                                            color: this.state.colorNationality,
                                          }}
                                        >
                                          Gender{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            type="text"
                                            className="form-control shadow-none"
                                            value={item.gender}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          className="form-label"
                                          style={{
                                            color: this.state.colorNationality,
                                          }}
                                        >
                                          State{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            type="text"
                                            className="form-control shadow-none"
                                            value={item.state}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{ color: this.state.colorLga }}
                                          className="form-label"
                                        >
                                          LGA{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            value={item.lga}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorNIFST,
                                          }}
                                          className="form-label"
                                        >
                                          CFSN Registration Number{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            onChange={(e) =>
                                              this.setState({
                                                nifst: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <hr />
                                      <div
                                        className="row"
                                        style={{ padding: 20 }}
                                      >
                                        <label
                                          className="mb-3 h4"
                                          style={{ color: "green" }}
                                          htmlFor="floatingInputCustom"
                                        >
                                          First Qualification
                                        </label>
                                        <br />
                                        <br />

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorQual,
                                            }}
                                            className="form-label"
                                          >
                                            Qualification{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <select
                                              className="form-control shadow-none"
                                              aria-label="Select"
                                              onChange={
                                                this.handleQualificationChange
                                              }
                                            >
                                              <option disabled selected>
                                                -- Select qualification --
                                              </option>
                                              <option value="S.S.C.E">
                                                S.S.C.E
                                              </option>
                                              <option value="OND">OND</option>
                                              <option value="HND">HND</option>
                                              <option value="PgD">PgD</option>
                                              <option value="B.Tech">
                                                B.Tech
                                              </option>
                                              <option value="B.SC">B.SC</option>
                                              <option value="M.Tech">
                                                M.Tech
                                              </option>
                                              <option value="M.SC">M.SC</option>
                                              <option value="Ph.D">Ph.D</option>
                                            </select>
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorQualYear,
                                            }}
                                            className="form-label"
                                          >
                                            Year of Qualification{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              required="required"
                                              onChange={(e) =>
                                                this.setState({
                                                  qualificationYear:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <hr />

                                      <div
                                        className="row"
                                        style={{ padding: 15 }}
                                      >
                                        <label
                                          className="mb-3 h4"
                                          style={{ color: "green" }}
                                          htmlFor="floatingInputCustom"
                                        >
                                          Second Qualification
                                        </label>
                                        <br />
                                        <br />

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label className="form-label">
                                            Second Qualification{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <select
                                              className="form-control shadow-none"
                                              aria-label="Select"
                                              onChange={
                                                this.handleQualificationChange2
                                              }
                                            >
                                              <option selected disabled>
                                                -- Select qualification --
                                              </option>
                                              <option value="S.S.C.E">
                                                S.S.C.E
                                              </option>
                                              <option value="OND">OND</option>
                                              <option value="HND">HND</option>
                                              <option value="PgD">PgD</option>
                                              <option value="B.Tech">
                                                B.Tech
                                              </option>
                                              <option value="B.SC">B.SC</option>
                                              <option value="M.Tech">
                                                M.Tech
                                              </option>
                                              <option value="M.SC">M.SC</option>
                                              <option value="Ph.D">Ph.D</option>
                                            </select>
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label className="form-label">
                                            Year of Qualification{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              required="required"
                                              onChange={(e) =>
                                                this.setState({
                                                  qualificationYear2:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <hr />

                                      <div
                                        className="row"
                                        style={{ padding: 15 }}
                                      >
                                        <label
                                          className="mb-3 h4"
                                          style={{ color: "green" }}
                                          htmlFor="floatingInputCustom"
                                        >
                                          Contact Information
                                        </label>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorPhone,
                                            }}
                                            className="form-label"
                                          >
                                            Phone{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="phone"
                                              required="required"
                                              onChange={(e) =>
                                                this.setState({
                                                  phone: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorEmail,
                                            }}
                                            className="form-label"
                                          >
                                            Email{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="email"
                                              required="required"
                                              onChange={(e) =>
                                                this.setState({
                                                  email: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        {localStorage.getItem(
                                          "userid"
                                        ) ? null : (
                                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                            <label
                                              style={{
                                                color: this.state.colorPassword,
                                              }}
                                              className="form-label"
                                            >
                                              Pasword{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <div className="input-group input-group-outline mb-3">
                                              <label className="form-label"></label>
                                              <input
                                                className="form-control shadow-none"
                                                type="text"
                                                required="required"
                                                onChange={(e) =>
                                                  this.setState({
                                                    password: e.target.value,
                                                  })
                                                }
                                                defaultValue={localStorage.getItem(
                                                  "password"
                                                )}
                                              />
                                            </div>
                                          </div>
                                        )}

                                        {localStorage.getItem(
                                          "userid"
                                        ) ? null : (
                                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                            <label
                                              style={{
                                                color:
                                                  this.state.colorPasswordConf,
                                              }}
                                              className="form-label"
                                            >
                                              Confirm Pasword{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <div className="input-group input-group-outline mb-3">
                                              <label className="form-label"></label>
                                              <input
                                                className="form-control shadow-none"
                                                type="text"
                                                required="required"
                                                onChange={(e) =>
                                                  this.setState({
                                                    confirmPassword:
                                                      e.target.value,
                                                  })
                                                }
                                                defaultValue={localStorage.getItem(
                                                  "confirmPassword"
                                                )}
                                              />
                                            </div>
                                          </div>
                                        )}

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color:
                                                this.state.colorMailAddress,
                                            }}
                                            className="form-label"
                                          >
                                            Mailing Address{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>

                                            <textarea
                                              className="form-control shadow-none"
                                              type="text"
                                              required="required"
                                              onChange={(e) =>
                                                this.setState({
                                                  mailingAddres: e.target.value,
                                                })
                                              }
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>

                                      <hr />
                                      <label
                                        className="mb-3 h4"
                                        style={{ color: "green" }}
                                        htmlFor="floatingInputCustom"
                                      >
                                        Place of work
                                      </label>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorOrgName,
                                          }}
                                          className="form-label"
                                        >
                                          Name of Organization/Institution{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            required="required"
                                            defaultValue={item.organisationname}
                                            onChange={(e) =>
                                              this.setState({
                                                orgName: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorPosition,
                                          }}
                                          className="form-label"
                                        >
                                          Position{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            defaultValue={
                                              item.organizationposition
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                position: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorAddress,
                                          }}
                                          className="form-label"
                                        >
                                          Address{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            required="required"
                                            onChange={(e) =>
                                              this.setState({
                                                address: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorOrgPhone,
                                          }}
                                          className="form-label"
                                        >
                                          Organization Telephone{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            required="required"
                                            onChange={(e) =>
                                              this.setState({
                                                orgPhone: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorOrgEmail,
                                          }}
                                          className="form-label"
                                        >
                                          Organization Email{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            required="required"
                                            onChange={(e) =>
                                              this.setState({
                                                orgEmail: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <hr />

                                      <label
                                        className="h4"
                                        htmlFor="floatingInputCustom"
                                        style={{ color: "green" }}
                                      >
                                        Practice Categoryd
                                      </label>
                                      <br />
                                      <br />

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorPraCat,
                                          }}
                                          className="form-label"
                                        >
                                          Practice Categorfy{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <select
                                            className="form-control shadow-none"
                                            aria-label="Select"
                                            onChange={this.handlePracticeChange}
                                            style={{
                                              color: this.state.colorPraCat,
                                            }}
                                          >
                                            <option selected disabled>
                                              -- Select category --
                                            </option>
                                            {this.showPracticeCategory()}
                                          </select>
                                        </div>
                                      </div>

                                      <hr />

                                      <label
                                        className="h4"
                                        htmlFor="floatingInputCustom"
                                        style={{ color: "green" }}
                                      >
                                        Current Licence
                                      </label>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorCurLic,
                                          }}
                                          className="form-label"
                                        >
                                          Licence Number{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            onChange={(e) =>
                                              this.setState({
                                                curLicense: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorCurLicDate,
                                          }}
                                          className="form-label"
                                        >
                                          Date{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="date"
                                            required="required"
                                            onChange={(e) =>
                                              this.setState({
                                                curLicenseDate: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <hr />

                                      <label
                                        className="h4"
                                        htmlFor="floatingInputCustom"
                                        style={{ color: "green" }}
                                      >
                                        Previous Licence
                                      </label>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorPrevLic,
                                          }}
                                          className="form-label"
                                        >
                                          Licence Number{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            onChange={(e) =>
                                              this.setState({
                                                prevLicense: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorPrevLicDate,
                                          }}
                                          className="form-label"
                                        >
                                          Date{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="date"
                                            required="required"
                                            onChange={(e) =>
                                              this.setState({
                                                prevLicenseDate: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label
                                          style={{
                                            color: this.state.colorPrevLicDate,
                                          }}
                                          className="form-label"
                                        >
                                          Expiry Date{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="date"
                                            required="required"
                                            onChange={(e) =>
                                              this.setState({
                                                expDate: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      <hr />

                                      <label
                                        className="h4"
                                        htmlFor="floatingInputCustom"
                                        style={{ color: "#145973" }}
                                      >
                                        Official
                                      </label>
                                      <br />
                                      <br />

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label className="form-label">
                                          Application Status
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className={
                                              item.applicationstatus ===
                                              "approved"
                                                ? "form-control shadow-none bg-success text-center text-uppercase font-weight-bold text-light"
                                                : item.applicationstatus ===
                                                  "pending"
                                                ? "form-control text-center text-uppercase font-weight-bold text-light shadow-none bg-warning"
                                                : item.applicationstatus ===
                                                  "rejected"
                                                ? "form-control text-center text-uppercase font-weight-bold text-light shadow-none bg-danger"
                                                : "form-control shadow-none"
                                            }
                                            type="text"
                                            value={item.applicationstatus}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label className="form-label">
                                          Licence Date
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            value={`${item.licensedate}`}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label className="form-label">
                                          Licence Number
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            value={`${item.licensenumber}`}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                        <label className="form-label">
                                          Licence Remarks
                                        </label>
                                        <div className="input-group input-group-outline mb-3">
                                          <label className="form-label"></label>
                                          <input
                                            className="form-control shadow-none"
                                            type="text"
                                            value={`${item.licenseremarks}`}
                                          />
                                        </div>
                                      </div>

                                      <div style={{ height: 45 }} />

                                      <label
                                        className="h5"
                                        htmlFor="floatingInputCustom"
                                      >
                                        Certificates
                                      </label>

                                      <div
                                        style={{
                                          flexDirection: "row",
                                          alignItems: "center",
                                          margin: 9,
                                          justifyContent: "space-between",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {item.qualificationimage1 ? (
                                          <img
                                            crossorigin="anonymous"
                                            width="306"
                                            height="306"
                                            src={`${item.qualificationimage1}`}
                                          />
                                        ) : (
                                          <img
                                            src="../assets/images/image.jpeg"
                                            alt="No image uploaded for qualification 1"
                                          />
                                        )}

                                        {item.qualificationimage2 ? (
                                          <img
                                            crossorigin="anonymous"
                                            width="306"
                                            height="306"
                                            src={`${item.qualificationimage2}`}
                                          />
                                        ) : (
                                          <img
                                            src="../assets/images/image.jpeg"
                                            alt="No image uploaded for qualification 2"
                                          />
                                        )}

                                        {item.qualificationimage3 ? (
                                          <img
                                              alt={``}
                                            crossorigin="anonymous"
                                            width="306"
                                            height="306"
                                            src={`${item.qualificationimage3}`}
                                          />
                                        ) : (
                                          <img
                                            src="../assets/images/image.jpeg"
                                            alt="No image uploaded for qualification 3"
                                          />
                                        )}

                                        {item.qualificationimage4 ? (
                                          <img
                                            crossorigin="anonymous"
                                            width="306"
                                            height="306"
                                            src={`${item.qualificationimage4}`}
                                          />
                                        ) : (
                                          <img
                                            src="../assets/images/image.jpeg"
                                            alt="No image uploaded for qualification 4"
                                          />
                                        )}

                                        {item.qualificationimage5 ? (
                                          <img
                                            crossorigin="anonymous"
                                            width="306"
                                            height="306"
                                            src={`${item.qualificationimage5}`}
                                          />
                                        ) : (
                                          <img
                                            src="../assets/images/image.jpeg"
                                            alt="No image uploaded for qualification 5"
                                          />
                                        )}
                                      </div>

                                      {/*  <div className="row" style={{ marginTop: 25 }}>
                            <Form.Floating className="mb-3">
                              <input
                                class="form-check-input shadow-none"
                                type="checkbox"
                                id="checkBoxUS"
                                name="US"
                                value="US"
                                onChange={this.handleCheckChange}
                              />{" "}
                              I hereby certify that I am not a registered and practicing
                              member of an allied profession and the particulars furnished
                              herein are true
                            </Form.Floating>
                          </div>

                          <div
                          className="text-center"
                            style={{
                              margin: "auto",
                              width: "100%",
                              marginTop: 45,
                              marginBottom: "15vh",
                            }}
                          >
                            <button
                              disabled={this.state.disabled}
                              style={{
                                alignSelf: "center",
                                width: "100%",
                                backgroundColor: "#003314",
                              }}
                              className="btn btn-success btn-lg"
                              onClick={(e) => this.checkValidation(e)}
                            >
                              {loading ? (
                                <Spinner animation="border" variant="light" size="sm" />
                              ) : (
                                <span className="font-weight-bold">
                                  {/* APPLY <i class="fas fa-chevron-right"></i> */}
                                      {/*  SUBMIT APPLICATION
                                </span>
                              )}
                            </button>
                          </div> */}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*   </div>*/}
                        </main>
                      );
                    })}
                </div>
              )}
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* End of View Modal */}

        {/* Start of Renewal Modal */}
        <div
          class="modal fade"
          id="verifyPayments"
          tabindex="-1"
          aria-labelledby="viewRunning"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div
                class="modal-header d-flex align-items-center justify-content-between"
                style={{ backgroundColor: "#00264C" }}
              >
                <h5 class="modal-title text-light font-weight-bold">
                  Renew Licence
                </h5>
                <button
                  type="button"
                  class="btn btn-link m-0 p-0 text-light fs-4"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span class="iconify" data-icon="carbon:close"></span>
                </button>
              </div>
              {isRenewalLoading ? (
                <center>
                  <Spinner
                    animation="border"
                    style={{ padding: 9 }}
                    variant="success"
                    size="lg"
                  />
                </center>
              ) : (
                <div class="modal-body">
                  <div class="container-fluid px-4">
                    {renewData.length > 0 &&
                      renewData.map((item) => {
                        localStorage.setItem("DOB", `${item.DOB}`);
                        localStorage.setItem("formid", `${item.formid}`);
                        localStorage.setItem("surname", `${item.surname}`);
                        localStorage.setItem(
                          "previoussurname",
                          `${item.previoussurname}`
                        );
                        localStorage.setItem("firstName", `${item.surname}`);
                        localStorage.setItem(
                          "othernames",
                          `${item.othernames}`
                        );
                        localStorage.setItem("title", `${item.title}`);
                        localStorage.setItem(
                          "nationality",
                          `${item.nationality}`
                        );
                        localStorage.setItem("gender", `${item.gender}`);
                        localStorage.setItem("state", `${item.state}`);
                        localStorage.setItem("lga", `${item.lga}`);
                        localStorage.setItem(
                          "nifstregistrationnumber",
                          `${item.nifstregistrationnumber}`
                        );
                        localStorage.setItem(
                          "courseofstudy",
                          `${item.courseofstudy}`
                        );
                        localStorage.setItem(
                          "tertiaryinstitution",
                          `${item.tertiaryinstitution}`
                        );
                        localStorage.setItem(
                          "qualification",
                          `${item.qualification}`
                        );
                        localStorage.setItem(
                          "yearofqualification",
                          `${item.yearofqualification}`
                        );
                        localStorage.setItem(
                          "contactaddress",
                          `${item.contactaddress}`
                        );
                        localStorage.setItem(
                          "contactemail",
                          `${item.contactemail}`
                        );
                        localStorage.setItem(
                          "practicecategory",
                          `${item.practicecategory}`
                        );
                        localStorage.setItem(
                          "organization",
                          `${item.organization}`
                        );
                        localStorage.setItem(
                          "organizationposition",
                          `${item.organizationposition}`
                        );
                        localStorage.setItem(
                          "organizationaddress",
                          `${item.organizationaddress}`
                        );
                        localStorage.setItem(
                          "organizationtelephone",
                          `${item.organizationtelephone}`
                        );
                        localStorage.setItem(
                          "organizationemail",
                          `${item.organizationemail}`
                        );
                        localStorage.setItem(
                          "sponsorname1",
                          `${item.sponsorname1}`
                        );
                        localStorage.setItem(
                          "sponsorcfsnno1",
                          `${item.sponsorcfsnno1}`
                        );
                        localStorage.setItem(
                          "sponsorcfsnno2",
                          `${item.sponsorcfsnno2}`
                        );
                        localStorage.setItem(
                          "sponsorname2",
                          `${item.sponsorname2}`
                        );
                        localStorage.setItem("recid", `${item.recid}`);

                        return (
                          <main
                            class="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
                            style={{
                              width: !localStorage.getItem("token")
                                ? "90%"
                                : "100%",
                              position: localStorage.getItem("token")
                                ? "relative"
                                : "",
                              right: 0,
                              padding: 18,
                              float: !localStorage.getItem("token")
                                ? ""
                                : "right",
                              marginBottom: 90,
                            }}
                          >
                            <div className="container-fluid px-4">
                              <div className="rown">
                                <div className="col-12">
                                  <div className="card my-3 mb-4">
                                    <div className="card-header pb-0 bg-success">
                                      <div className="text-center">
                                        <h5 className="text-light text-center font-weight-bold mb-4">
                                          Renewal Form for Licence
                                        </h5>
                                      </div>
                                    </div>
                                    {/* <div class="card-body"> */}

                                    <div
                                      className="container"
                                      style={{ marginTop: 18, padding: 9 }}
                                    >
                                      <div style={{ marginTop: 0 }}></div>
                                      <form className="row">
                                        <label
                                          className="mb-3 h4"
                                          style={{ color: "#145973" }}
                                          htmlFor="floatingInputCustom"
                                        >
                                          Personal Information
                                        </label>
                                        <br />
                                        <br />
                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorSurname,
                                            }}
                                            className="form-label"
                                          >
                                            Surname{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control w-50 shadow-none"
                                              type="text"
                                              required="required"
                                              defaultValue={item.surname}
                                              onChange={(e) =>
                                                this.setState({
                                                  surname: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label className="form-label text-dark">
                                            Previous Surname
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control w-50 shadow-none"
                                              type="text"
                                              defaultValue={
                                                item.previoussurname
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  prevSurname: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorOthername,
                                            }}
                                            className="form-label"
                                          >
                                            Other name(s){" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={item.othernames}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorTitle,
                                            }}
                                            className="form-label"
                                          >
                                            Title{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <select
                                              className="form-control"
                                              aria-label="Select title"
                                              onChange={this.handleChange}
                                              style={{
                                                color: this.state.colorTitle,
                                              }}
                                            >
                                              <option
                                                value={
                                                  item.title
                                                    ? item.title
                                                    : "-- Select title --"
                                                }
                                                selected
                                                disabled
                                              >
                                                {item.title
                                                  ? item.title
                                                  : "-- Select title --"}
                                              </option>
                                              <option value="mr">Mr.</option>
                                              <option value="mrs">Mrs.</option>
                                              <option value="ms">Ms.</option>
                                              <option value="dr">Dr.</option>
                                              <option value="prof">
                                                Prof.
                                              </option>
                                            </select>
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorDob,
                                            }}
                                            className="form-label"
                                          >
                                            DOB{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={item.DOB}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            className="form-label"
                                            style={{
                                              color:
                                                this.state.colorNationality,
                                            }}
                                          >
                                            Nationality{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              type="text"
                                              className="form-control shadow-none"
                                              defaultValue={item.nationality}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            className="form-label"
                                            style={{
                                              color: this.state.colorGender,
                                            }}
                                          >
                                            Select Gender{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <select
                                              className="form-control shadow-none"
                                              aria-label="Select"
                                              onChange={this.handleGenderChange}
                                              style={{
                                                color: this.state.colorGender,
                                              }}
                                            >
                                              <option
                                                value={
                                                  item.gender
                                                    ? item.gender
                                                    : "-- Select gender--"
                                                }
                                                selected
                                              >
                                                {item.gender
                                                  ? item.gender
                                                  : "-- Select gender --"}
                                              </option>
                                              <option value="male">Male</option>
                                              <option value="female">
                                                Female
                                              </option>
                                            </select>
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorState,
                                            }}
                                            className="form-label"
                                          >
                                            Select State{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <select
                                              className="form-control shadow-none"
                                              aria-label="Select state"
                                              onChange={this.handleStateChange}
                                              style={{
                                                color: this.state.colorState,
                                              }}
                                            >
                                              <option
                                                value={
                                                  item.state
                                                    ? item.state
                                                    : "-- Select state --"
                                                }
                                                selected
                                              >
                                                {item.state
                                                  ? item.state
                                                  : "-- Select state --"}
                                              </option>
                                              {this.getStates()}
                                            </select>
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorLga,
                                            }}
                                            className="form-label"
                                          >
                                            LGA{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              required="required"
                                              defaultValue={item.lga}
                                              onChange={(e) =>
                                                this.setState({
                                                  lga: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorNIFST,
                                            }}
                                            className="form-label"
                                          >
                                            NIFST Registration Number{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={
                                                item.nifstregistrationnumber
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  nifst: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <hr />
                                        <label
                                          className="mb-3 h4"
                                          style={{ color: "#145973" }}
                                          htmlFor="floatingInputCustom"
                                        >
                                          Qualification
                                        </label>
                                        <br />
                                        <br />

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorStudy,
                                            }}
                                            className="form-label"
                                          >
                                            Course of study
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={item.courseofstudy}
                                              onChange={(e) =>
                                                this.setState({
                                                  courseStudy: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorTetiary,
                                            }}
                                            className="form-label"
                                          >
                                            Tertiary Institution
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={
                                                item.tertiaryinstitution
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  tertiaryinstitution:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorQual,
                                            }}
                                            className="form-label"
                                          >
                                            Qualification{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <select
                                              className="form-control shadow-none"
                                              aria-label="Select"
                                              onChange={
                                                this.handleQualificationChange
                                              }
                                              style={{
                                                color: this.state.colorQual,
                                              }}
                                            >
                                              <option
                                                value={
                                                  item.qualification
                                                    ? item.qualification
                                                    : "-- Select qualification --"
                                                }
                                                selected
                                              >
                                                {item.qualification
                                                  ? item.qualification
                                                  : "-- Select qualification --"}
                                              </option>
                                              <option value="S.S.C.E">
                                                S.S.C.E
                                              </option>
                                              <option value="OND">OND</option>
                                              <option value="HND">HND</option>
                                              <option value="PgD">PgD</option>
                                              <option value="B.Tech">
                                                B.Tech
                                              </option>
                                              <option value="B.SC">B.SC</option>
                                              <option value="M.Tech">
                                                M.Tech
                                              </option>
                                              <option value="M.SC">M.SC</option>
                                              <option value="Ph.D">Ph.D</option>
                                            </select>
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorQualYear,
                                            }}
                                            className="form-label"
                                          >
                                            Year of Qualification{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={
                                                item.yearofqualification
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  qualificationYear:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <hr />

                                        <label
                                          className="mb-3 h4"
                                          style={{ color: "#145973" }}
                                          htmlFor="floatingInputCustom"
                                        >
                                          Contact Information
                                        </label>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color:
                                                this.state.colorMailAddress,
                                            }}
                                            className="form-label"
                                          >
                                            Mailing Address{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>

                                            <textarea
                                              className="form-control shadow-none"
                                              type="text"
                                              required="required"
                                              rows="1"
                                              defaultValue={item.contactaddress}
                                              onChange={(e) =>
                                                this.setState({
                                                  mailingAddres: e.target.value,
                                                })
                                              }
                                            ></textarea>
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            className="form-label"
                                            style={{
                                              color: this.state.colorEmail,
                                            }}
                                          >
                                            Email{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="email"
                                              defaultValue={item.contactemail}
                                              onChange={(e) =>
                                                this.setState({
                                                  email: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            className="form-label"
                                            style={{
                                              color:
                                                this.state.practicecategory,
                                            }}
                                          >
                                            Practice Categoryw{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <select
                                              className="form-control shadow-none"
                                              aria-label="Select"
                                              onChange={
                                                this.handlePracticeChange
                                              }
                                              style={{
                                                color: this.state.colorPractice,
                                              }}
                                            >
                                              <option
                                                value={
                                                  item.practiceCategory
                                                    ? item.practiceCategory
                                                    : "-- Select practice category --"
                                                }
                                                selected
                                              >
                                                {item.practiceCategory
                                                  ? item.practiceCategory
                                                  : "-- Select practice category --"}
                                              </option>
                                              {this.showPracticeCategory()}
                                            </select>
                                          </div>
                                        </div>

                                        <hr />
                                        <label
                                          className="mb-3 h4"
                                          style={{ color: "#145973" }}
                                          htmlFor="floatingInputCustom"
                                        >
                                          Place of work
                                        </label>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorOrgName,
                                            }}
                                            className="form-label"
                                          >
                                            Name of Organization/Institution{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              defaultValue={item.organization}
                                              onChange={(e) =>
                                                this.setState({
                                                  orgName: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorPosition,
                                            }}
                                            className="form-label"
                                          >
                                            Position{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={
                                                item.organizationposition
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  orgPosition: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorOrgAddress,
                                            }}
                                            className="form-label"
                                          >
                                            Address{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={
                                                item.organizationaddress
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  orgAddress: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorOrgPhone,
                                            }}
                                            className="form-label"
                                          >
                                            Organization Telephone{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={
                                                item.organizationtelephone
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  orgPhone: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorOrgEmail,
                                            }}
                                            className="form-label"
                                          >
                                            Organization Email{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={
                                                item.organizationemail
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  orgEmail: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <hr />

                                        <label
                                          className="h4"
                                          htmlFor="floatingInputCustom"
                                          style={{ color: "#145973" }}
                                        >
                                          Sponsor Information
                                        </label>
                                        <br />
                                        <br />

                                        <label
                                          className="h5"
                                          htmlFor="floatingInputCustom"
                                        >
                                          Sponsor 1
                                        </label>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorSponsor,
                                            }}
                                            className="form-label"
                                          >
                                            Sponsor Name{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={item.sponsorname1}
                                              onChange={(e) =>
                                                this.setState({
                                                  sponsorName: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorSponsorNo,
                                            }}
                                            className="form-label"
                                          >
                                            Current CFSN Number{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={item.sponsorcfsnno1}
                                              onChange={(e) =>
                                                this.setState({
                                                  sponsorNo: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <label
                                          className="h5"
                                          htmlFor="floatingInputCustom"
                                        >
                                          Sponsor 2
                                        </label>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label className="form-label">
                                            Sponsor Name
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={item.sponsorname2}
                                              onChange={(e) =>
                                                this.setState({
                                                  sponsorName2: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label className="form-label">
                                            Current CFSN Number
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <input
                                              className="form-control shadow-none"
                                              type="text"
                                              defaultValue={item.sponsorcfsnno2}
                                              onChange={(e) =>
                                                this.setState({
                                                  sponsorNo2: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                        <hr />

                                        <label
                                          className="h5"
                                          htmlFor="floatingInputCustom"
                                        >
                                          Payments
                                        </label>

                                        <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                          <label
                                            style={{ color: "black" }}
                                            className="form-label"
                                          >
                                            Select Payment{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <div className="input-group input-group-outline mb-3">
                                            <label className="form-label"></label>
                                            <select
                                              className="form-control shadow-none"
                                              aria-label="Select"
                                              onChange={this.handleFeeChange}
                                              style={{
                                                color: this.state.colorCompany,
                                              }}
                                            >
                                              <option selected="selected">
                                                -- Select payment type --
                                              </option>
                                              {this.showFeeTypes()}
                                            </select>
                                          </div>
                                        </div>
                                        <hr />

                                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                          <label
                                            style={{
                                              color: this.state.colorNIFSTImage,
                                            }}
                                            htmlFor="floatingInputCustom"
                                          >
                                            Upload Your NIFST Certificate Image
                                          </label>{" "}
                                          <span className="text-danger">*</span>
                                          <div className="col-sm-6 col-lg-12 col-md-6 mb-3">
                                            <input
                                              className="form-control shadow-none"
                                              type="file"
                                              required="required"
                                              onChange={
                                                this.handleNIFSTCertificateImage
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div style={{ height: 45 }} />

                                        {/*Create Login details */}

                                        {!localStorage.getItem("userid") && (
                                          <label
                                            className="h4"
                                            htmlFor="floatingInputCustom"
                                            style={{ color: "#145973" }}
                                          >
                                            Create Login Details <br />
                                            <span
                                              className="text-danger"
                                              style={{ fontSize: 18 }}
                                            >
                                              Use this account to login to your
                                              dashboard for tracking, renewal
                                              and subsequent applications.
                                            </span>
                                            <br />
                                            <br />
                                          </label>
                                        )}
                                        {!localStorage.getItem("userid") && (
                                          <br />
                                        )}
                                        {!localStorage.getItem("userid") && (
                                          <br />
                                        )}

                                        {!localStorage.getItem("userid") && (
                                          <div
                                            className="col-sm-6 col-lg-4 col-md-6 mb-3"
                                            style={{ display: "none" }}
                                          >
                                            <label
                                              style={{
                                                color:
                                                  this.state.colorCountryCode,
                                              }}
                                              className="form-label"
                                            >
                                              Country{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <div className="input-group input-group-outline mb-3">
                                              <label className="form-label"></label>
                                              <select
                                                className="form-control shadow-none"
                                                aria-label="Floating label select example"
                                                onChange={
                                                  this.handleCountryChange
                                                }
                                              >
                                                <option
                                                  value="choose"
                                                  selected="selected"
                                                >
                                                  -- Select country code --
                                                </option>
                                                {this.getCountry()}
                                              </select>
                                            </div>
                                          </div>
                                        )}

                                        {!localStorage.getItem("userid") && (
                                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                            <label
                                              style={{
                                                color: this.state.colorPhone,
                                              }}
                                              className="form-label"
                                            >
                                              Phone{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <div className="input-group input-group-outline mb-3">
                                              <label className="form-label"></label>
                                              <input
                                                className="form-control shadow-none"
                                                type="phone"
                                                required="required"
                                                onChange={(e) =>
                                                  this.setState({
                                                    phone: e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}

                                        {!localStorage.getItem("userid") && (
                                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                            <label
                                              style={{
                                                color: this.state.colorPassword,
                                              }}
                                              className="form-label"
                                            >
                                              Pasword{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <div className="input-group input-group-outline mb-3">
                                              <label className="form-label"></label>
                                              <input
                                                className="form-control shadow-none"
                                                type="text"
                                                required="required"
                                                onChange={(e) =>
                                                  this.setState({
                                                    password: e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}

                                        {!localStorage.getItem("userid") && (
                                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                            <label
                                              style={{
                                                color:
                                                  this.state.colorPasswordConf,
                                              }}
                                              className="form-label"
                                            >
                                              Confirm Pasword{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <div className="input-group input-group-outline mb-3">
                                              <label className="form-label"></label>
                                              <input
                                                className="form-control shadow-none"
                                                type="text"
                                                required="required"
                                                onChange={(e) =>
                                                  this.setState({
                                                    confirmPassword:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div>
                                        )}

                                        <div
                                          className="row"
                                          style={{ marginTop: 25 }}
                                        >
                                          <Form.Floating className="mb-3">
                                            <input
                                              class="form-check-input shadow-none"
                                              type="checkbox"
                                              id="checkBoxUS"
                                              name="US"
                                              value="US"
                                              onChange={this.handleCheckChange}
                                            />{" "}
                                            I hereby certify that I am not a
                                            registered and practicing member of
                                            an allied profession and the
                                            particulars furnished herein are
                                            true
                                          </Form.Floating>
                                          <h6>
                                            TAKE NOTICE: THE NICFOST SHALL MAKE
                                            A CLAIM AND RECOVER ALL COSTS OF
                                            LITIGATION INCURRED BY IN IN DEFENCE
                                            OF ANY COURT ACTION INSTITUTED
                                            AGAINST IT AT THE INSTANCE OF ANY
                                            CERTIFIED FOOD SCIENTIST AND/OR
                                            LICENSED FOOD SCIENTIST PREMISES AND
                                            WHEREBY THE SUIT IS STRUCK OUT,
                                            WITHDRAWN OR THE FOOD SCIENTIST OR
                                            THE FOOD SCIENTIST PREMISES LOSES
                                            THE CASE.
                                          </h6>
                                        </div>

                                        <div
                                          className="text-center"
                                          style={{
                                            margin: "auto",
                                            width: "100%",
                                            marginTop: 45,
                                            marginBottom: 0,
                                          }}
                                        >
                                          <button
                                            disabled={this.state.disabled}
                                            style={{
                                              alignSelf: "center",
                                              width: "60%",
                                              backgroundColor: "#147332",
                                            }}
                                            className="btn btn-success btn-lg"
                                            onClick={(e) =>
                                              this.checkValidation(e)
                                            }
                                          >
                                            {loading ? (
                                              <Spinner
                                                animation="border"
                                                variant="light"
                                                size="sm"
                                              />
                                            ) : (
                                              <span className="font-weight-bold">
                                                {/* APPLY <i class="fas fa-chevron-right"></i> */}
                                                SUBMIT APPLICATION
                                              </span>
                                            )}
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </main>
                        );
                      })}
                  </div>
                </div>
              )}

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* End of Renewal Modal */}
      </div>
    );
  }
}

export default RenewLicence;

import React, {Component} from 'react'
import NewDashboardLayout from '../components/NewDashboardlayout';
import {Spinner, Form, Alert} from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import {states} from "../assets/data/json-data";
import Swal from 'sweetalert2';
import {makePayment} from '../Helper/paymentHelper';


const baseUrl = process.env.REACT_APP_BASE_URL

class NewRegisterLicence extends Component {
    constructor(props) {

        super(props);
        this.state = {
            surname: "",
            prevSurname: "",
            otherNames: "",
            title: "",
            dob: "",
            nationality: "",
            gender: "",
            state: "",
            lga: "",
            selectedFile: null,
            qualifications: [],
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",

            organizationName: "",
            organizationPosition: "",
            organizationEmail: "",
            organizationTelephone: "",
            organizationAddress: "",

            practiceCategory: "",
            employed: false,
            loading: false,
            disabled: false,

            colorSurname: "black",
            colorOthername: "black",
            colorTitle: "black",
            colorDob: "black",
            colorNationality: "black",
            colorGender: "black",
            colorState: "black",
            colorLga: "black",

            colorPhone: "black",
            colorEmail: "black",
            colorAddress: "black",
            colorPraCat: "black",

            colorOrgName: "black",
            colorOrgEmail: "black",
            colorOrgPhone: "black",
            colorOrgAddress: "black",
            colorOrgPosition: "black",
        }

        this.addQualification = this.addQualification.bind(this);
        this.updateQualification = this.updateQualification.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);

        this.handleEmployedCheck = this.handleEmployedCheck.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }


    getStates() {
        const state = states.map((state) => state.name);
        return state?.map((each, i) => (
            <option key={i} value={each}>
                {each}
            </option>
        ))
    }

    getLgas() {
        const stateVal = this?.state?.state;
        if (stateVal) {
            const choosenState = states.find((state) => state.name === stateVal);
            return choosenState?.locals?.map(({name, i}) => (
                <option value={name} key={i}>
                    {name}
                </option>
            ));
        }
    }

    handleDateChange(date, e) {
        const dob = date.target.value;
        this.setState({
            dob: dob,
            colorDob: "black",
        });
    }

    addQualification = () => {
        this.setState(prevState => ({
            qualifications: [
                ...prevState.qualifications,
                { qualification: "", year: "", certificateImage: null }
            ]
        }));
    }

    updateQualification = (index, field, value) => {
        const newQualifications = [...this.state.qualifications];
        newQualifications[index] = {
            ...newQualifications[index],
            [field]: value
        };
        this.setState({ qualifications: newQualifications });
    }

    handleFileChange = (index, file) => {
        this.convertToBase64(file, index);
    }

    convertToBase64 = (file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.updateQualification(index, 'certificateImage', reader.result);
        };
        reader.onerror = (error) => {
            console.error("Error converting file to base64:", error);
        };
    };

    handleStateChange(e) {
        const stateVal = e.target.value;
        this.setState({state: stateVal, colorState: "black"});
    }

    handleEmployedCheck(e) {
        this.setState({employed: e.target.checked});
    }


    getlicenseInfo() {
        const self = this;
        const userid = localStorage.getItem("userid");
        axios
            .get(`${baseUrl}/v2/license/license-information/${userid}`)
            .then(async (response) => {
                this.setState((prevState) => ({
                    ...prevState,
                    ...response.data.personalInformation,
                    ...response.data.contactInformation,
                    qualifications: [...response.data.qualifications],
                    ...response.data.workInformation,
                    ...response.data.practiceCategory,
                }));
            })
            .catch((err) => {
                const response = err.response;
                this.setState({loading: false, disabled: false});
                switch (response?.status) {
                    case 400:
                        Swal.fire({
                            title: "Error!",
                            text: response.data.message,
                            icon: "error",
                            confirmButtonText: "OK",
                        }).then(() => {
                            self.props.history.push("/new-dashboard");
                        });
                        break;
                    default:
                        Swal.fire({
                            title: "Error!",
                            text: err.message,
                            icon: "error",
                            confirmButtonText: "OK",
                        });
                        break;
                }
            });
    }

    componentDidMount() {
        this.getlicenseInfo();
    }



    checkValidation = async (e) => {
        e.preventDefault();

        const {
            surname,
            otherNames,
            title,
            dob,
            nationality,
            gender,

            contactPhone,
            contactEmail,
            contactAddress,
            qualifications,
            organizationName,
            organizationPosition,
            organizationEmail,
            organizationTelephone,
            organizationAddress,

            practiceCategory,
            employed,
        } = this.state;


        if (surname === "" || surname === null) {
            Swal.fire({
                title: "Empty",
                text: "Please enter your surname",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorSurname: "red"});
        } else if (otherNames === "" || otherNames === null) {
            Swal.fire({
                title: "Empty",
                text: "Please specify your other name",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorOthername: "red"});
        } else if (title === "" || title === null) {
            Swal.fire({
                title: "Empty",
                text: "Please specify your title",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorTitle: "red"});
        } else if (dob === "" || dob === null) {
            Swal.fire({
                title: "Empty",
                text: "Please specify your Date of birth",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorDob: "red"});
        } else if (nationality === "" || nationality === null) {
            Swal.fire({
                title: "Empty",
                text: "Please specify your nationality",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({
                loading: false,
                disabled: false,
                colorNationality: "red",
            });
        } else if (gender === "" || gender === null) {
            Swal.fire({
                title: "Empty",
                text: "Please specify your gender",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorGender: "red"});
        } else if (contactPhone === "" || contactPhone === null) {
            Swal.fire({
                title: "Empty",
                text: "Please specify your Phone Number",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorPhone: "red"});
        } else if (contactEmail === "" || contactEmail === null) {
            Swal.fire({
                title: "Empty",
                text: "Please verify your email",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorEmail: "red"});
        } else if (contactAddress === "" || contactAddress === null) {
            Swal.fire({
                title: "Empty",
                text: "Please specify your Contact Address",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({
                loading: false,
                disabled: false,
                colorAddress: "red",
            });
        } else if (employed && (organizationName === "" || organizationName === null)) {
            Swal.fire({
                title: "Empty",
                text: "Please specify your Organization Name",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorOrgName: "red"});
        } else if (employed && (organizationPosition === "" || organizationPosition === null)) {
            Swal.fire({
                title: "Empty",
                text: "Please specify your position in your Organization",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorPosition: "red"});
        } else if (employed && (organizationAddress === "" || organizationAddress === null)) {
            Swal.fire({
                title: "Empty",
                text: "Please specify your Organization address",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorOrgAddress: "red"});
        } else if (employed && (organizationTelephone === "" || organizationTelephone === null)) {
            Swal.fire({
                title: "Empty",
                text: "Please specify phone number of your Organization",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorOrgPhone: "red"});
        } else if (employed && (organizationEmail === "" || organizationEmail === null)) {
            Swal.fire({
                title: "Empty",
                text: "Please specify email of your Organization",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorOrgEmail: "red"});
        } else if (practiceCategory === "" || practiceCategory === null) {
            Swal.fire({
                title: "Empty",
                text: "Please specify your practice category",
                icon: "error",
                confirmButtonText: "OK",
            });
            this.setState({loading: false, disabled: false, colorPraCat: "red"});
        } else {

            this.setState({loading: true, disabled: true});
            this.renewLicence();
        }
    }


    registerLicence = () => {
        this.setState({loading: true, disabled: true});

        let date = new Date();
        var obj = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                dob: this.state.dob,
                applicationdate: date.getTime(),
                contactAddress: this.state.contactAddress,
                contactEmail: this.state.contactEmail,
                contactTelephone: this.state.contactPhone,
                formType: "registration",
                gender: this.state.gender,
                lga: this.state.lga,
                nationality: this.state.nationality,
                organization: this.state.organizationName,
                organizationAddress: this.state.organizationAddress,
                organizationEmail: this.state.organizationEmail,
                organizationPosition: this.state.organizationPosition,
                organizationTelephone: this.state.organizationTelephone,
                otherNames: this.state.otherNames,
                practiceCategory: this.state.practiceCategory,
                previoussurname: this.state.prevSurname,
                registrationnumber: this.state.nifst,
                state: this.state.state,
                surname: this.state.surname,
                title: this.state.title,
                userId: localStorage.getItem("userid"),
            }),
        };


        fetch(`${baseUrl}/v2/license/create-license`, obj)
            .then(async (response) => {
                if (response?.status === 200) {
                    const responseJson = await response?.json();
                    localStorage.setItem("rrr", responseJson.rrr);
                    localStorage.setItem("license_id", responseJson.licenseId)

                    makePayment()
                    this.setState({loading: false, disabled: false});
                }

                if (response?.status === 400) {
                    const responseJson = await response.json();
                    Swal.fire({
                        title: "Error",
                        text: responseJson?.violations[0]?.message || responseJson?.message,
                        icon: "error",
                        confirmButtonText: "OK",
                    }).then(() => {
                        this.props.history.push("/new-dashboard")
                    });
                }

                if (response?.status === 500) {
                    Swal.fire({
                        title: "Error",
                        text: "Unable to apply for a license at the moment. Please try again later.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }

                this.setState({loading: false, disabled: false});
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error!",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            });

    };


    renewLicence = () => {
        this.setState({loading: true, disabled: true});

        let date = new Date();


        let params = this.props.match.params.licenceId;
        var obj = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                dob: this.state.dob,
                applicationdate: date.getTime(),
                contactAddress: this.state.contactAddress,
                contactEmail: this.state.contactEmail,
                contactTelephone: this.state.contactPhone,
                formType: "registration",
                gender: this.state.gender,
                lga: this.state.lga,
                nationality: this.state.nationality,
                organization: this.state.organizationName,
                organizationAddress: this.state.organizationAddress,
                organizationEmail: this.state.organizationEmail,
                organizationPosition: this.state.organizationPosition,
                organizationTelephone: this.state.organizationTelephone,
                otherNames: this.state.otherNames,
                practiceCategory: this.state.practiceCategory,
                previoussurname: this.state.prevSurname,
                registrationnumber: this.state.nifst,
                qualifications: this.state.qualifications,
                state: this.state.state,
                surname: this.state.surname,
                title: this.state.title,
                userId: localStorage.getItem("userid"),
            }),
        };

        fetch(`${baseUrl}/v2/license/renew-license/${params}`, obj)
            .then(async (response) => {
                if (response?.status === 200) {
                    const responseJson = await response?.json();
                    localStorage.setItem("rrr", responseJson.rrr);
                    localStorage.setItem("license_id", responseJson.licenseId)

                    makePayment()
                    this.setState({loading: false, disabled: false});
                    this.props.history.push("/new-dashboard")
                }

                if (response?.status === 400) {
                    const responseJson = await response.json();
                    Swal.fire({
                        title: "Error",
                        text: responseJson?.violations[0]?.message || responseJson?.message,
                        icon: "error",
                        confirmButtonText: "OK",
                    })
                }

                if (response?.status === 500) {
                    Swal.fire({
                        title: "Error",
                        text: "Unable to apply for a license at the moment. Please try again later.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }

                this.setState({loading: false, disabled: false});
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error!",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }).finally(
            this.setState({loading: false, disabled: false})
        );

    };



    render() {
        const {loading, disabled, employed, qualifications} = this.state;
        return (
            <NewDashboardLayout PageName="Licence Renewal">
                <div className="container-fluid px-4" id="register-form-container">
                    <div className="rown">
                        <div className="col-12">
                            <div className="card my-3">
                                <div className="card-header pb-0" style={{backgroundColor: "rgb(6 38 1)"}}>
                                    <div className="d-flex flex-wrap justify-content-center mb-2">
                                        <h5 className="text-light text-center font-weight-bold">
                                            APPLICATION FORM FOR LICENCE OR RENEWAL TO PRACTICE AS A
                                            CERTIFIED FOOD SCIENTIST
                                        </h5>
                                    </div>
                                </div>
                                <div class="card-body reg-body">
                                    <h3
                                        className="nicfostText"
                                        style={{
                                            position: "relative",
                                            fontWeight: "bold",
                                            top: 0,
                                            fontSize: 25,
                                            marginLeft: 85,
                                            textAlign: "center",
                                        }}
                                    >
                                        NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NicFosT)
                                    </h3>

                                    <h5
                                        style={{
                                            position: "relative",
                                            fontWeight: "bold",
                                            top: 65,
                                            fontSize: 20,
                                            marginLeft: 85,
                                            textAlign: "center",
                                        }}
                                    ></h5>
                                    <div
                                        className="container"
                                        style={{marginTop: 0, padding: 18}}
                                    >

                                        <form className="row">
                                            <label
                                                className="mb-3 h4"
                                                style={{color: "green"}}
                                                htmlFor="floatingInputCustom"
                                            >
                                                Personal Information
                                            </label>
                                            <br/>
                                            <br/>
                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                <label
                                                    style={{color: this.state.colorSurname}}
                                                    className="form-label"
                                                >
                                                    Surname <span className="text-danger">*</span>
                                                </label>
                                                <div className="input-group input-group-outline mb-3">
                                                    <label className="form-label"></label>
                                                    <input
                                                        className="form-control shadow-none"
                                                        type="text"
                                                        value={this?.state?.surname}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                surname: e.target.value,
                                                                colorSurname: "black",
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                <label className="form-label text-dark">
                                                    Previous Surname (If applicable)
                                                </label>
                                                <div className="input-group input-group-outline mb-3">
                                                    <label className="form-label"></label>
                                                    <input
                                                        className="form-control shadow-none"
                                                        type="text"
                                                        value={this?.state?.prevSurname}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                prevSurname: e.target.value,
                                                                colorprevState: "black",
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                <label
                                                    style={{color: this.state.colorOthername}}
                                                    className="form-label"
                                                >
                                                    Other name(s) <span className="text-danger">*</span>
                                                </label>
                                                <div className="input-group input-group-outline mb-3">
                                                    <label className="form-label"></label>
                                                    <input
                                                        className="form-control shadow-none"
                                                        type="text"
                                                        value={this?.state?.otherNames}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                otherNames: e.target.value,
                                                                colorOthername: "black",
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                <label
                                                    style={{color: this.state.colorTitle}}
                                                    className="form-label"
                                                >
                                                    Title <span className="text-danger">*</span>
                                                </label>
                                                <div className="input-group input-group-outline mb-3">
                                                    <label className="form-label"></label>
                                                    <select
                                                        className="form-control"
                                                        aria-label="Select title"
                                                        onChange={this.handleChange}
                                                        value={this?.state?.title && this?.state?.title}
                                                        style={{color: this.state.colorTitle}}
                                                    >
                                                        <option selected disabled>
                                                            -- Select title --
                                                        </option>
                                                        <option value="mr">Mr.</option>
                                                        <option value="mrs">Mrs.</option>
                                                        <option value="ms">Ms.</option>
                                                        <option value="dr">Dr.</option>
                                                        <option value="prof">Prof.</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                <label
                                                    style={{color: this.state.colorDob}}
                                                    className="form-label"
                                                >
                                                    DOB <span className="text-danger">*</span>
                                                </label>

                                                <Form.Control
                                                    name="startDate"
                                                    value={moment(this?.state?.dob).format(
                                                        "yyyy-MM-DD"
                                                    )}
                                                    placeholder="Enter start date"
                                                    onChange={this.handleDateChange}
                                                    // isInvalid={!!errors.dob}
                                                    type="date"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                <label
                                                    className="form-label"
                                                    style={{color: this.state.colorNationality}}
                                                >
                                                    Nationality <span className="text-danger">*</span>
                                                </label>
                                                <div className="input-group input-group-outline mb-3">
                                                    <label className="form-label"></label>
                                                    <input
                                                        type="text"
                                                        className="form-control shadow-none"
                                                        value={
                                                            this?.state?.nationality &&
                                                            this?.state?.nationality
                                                        }
                                                        onChange={(e) =>
                                                            this.setState({
                                                                nationality: e.target.value,
                                                                colorNationality: "black",
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                <label
                                                    className="form-label"
                                                    style={{color: this.state.colorGender}}
                                                >
                                                    Select Gender <span className="text-danger">*</span>
                                                </label>
                                                <div className="input-group input-group-outline mb-3">
                                                    <label className="form-label"></label>
                                                    <select
                                                        className="form-control shadow-none"
                                                        aria-label="Select"
                                                        value={
                                                            this?.state?.gender ? this?.state?.gender : null
                                                        }
                                                        onChange={this.handleGenderChange}
                                                        style={{color: this.state.colorGender}}
                                                    >
                                                        <option disabled selected>
                                                            -- Select gender --
                                                        </option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                <label
                                                    style={{color: this.state.colorState}}
                                                    className="form-label"
                                                >
                                                    Select State <span className="text-danger">*</span>
                                                </label>
                                                <div className="input-group input-group-outline mb-3">
                                                    <label className="form-label"></label>
                                                    <select
                                                        className="form-control shadow-none"
                                                        aria-label="Select state"
                                                        value={
                                                            this?.state?.state ? this?.state?.state : null
                                                        }
                                                        onChange={this.handleStateChange}
                                                        style={{color: this.state.colorState}}
                                                    >
                                                        <option disabled selected>
                                                            -- Select state --
                                                        </option>

                                                        {this.getStates()}
                                                    </select>
                                                </div>
                                            </div>

                                            {this?.state?.state && (
                                                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                    <label
                                                        style={{color: this.state.colorLga}}
                                                        className="form-label"
                                                    >
                                                        LGA <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="input-group input-group-outline mb-3">
                                                        <label className="form-label"></label>
                                                        <select
                                                            className="form-control shadow-none"
                                                            aria-label="Select state"
                                                            value={
                                                                this?.state?.lga ? this?.state?.lga : null
                                                            }
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    lga: e.target.value,
                                                                    colorLga: "black",
                                                                })
                                                            }
                                                            style={{color: this.state.colorLga}}
                                                        >
                                                            <option disabled selected>
                                                                -- Select lga --
                                                            </option>
                                                            {this.getLgas()}
                                                            {/* {this.state.getLgas.length > 0 && this.state?.getLgas.map(({name, i}) => (<option value={name} key={i}>{name}</option>) )} */}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}


                                            <hr/>

                                            <div className="row" style={{padding: 15}}>
                                                <label
                                                    className="mb-3 h4"
                                                    style={{color: "green"}}
                                                    htmlFor="floatingInputCustom"
                                                >
                                                    Contact Information
                                                </label>

                                                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                    <label
                                                        style={{color: this.state.colorPhone}}
                                                        className="form-label"
                                                    >
                                                        Phone <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="input-group input-group-outline mb-3">
                                                        <label className="form-label"></label>
                                                        <input
                                                            className="form-control shadow-none"
                                                            type="number"
                                                            value={this?.state?.contactPhone}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    contactPhone: e.target.value,
                                                                    colorPhone: "black",
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                    <label
                                                        style={{color: this.state.colorEmail}}
                                                        className="form-label"
                                                    >
                                                        Email <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="input-group input-group-outline mb-3">
                                                        <label className="form-label"></label>
                                                        <input
                                                            className="form-control shadow-none"
                                                            type="email"
                                                            required="required"
                                                            value={this?.state?.contactEmail}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    contactEmail: e.target.value,
                                                                    colorEmail: "black",
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                    <label
                                                        style={{color: this.state.colorAddress}}
                                                        className="form-label"
                                                    >
                                                        Contact Address{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="input-group input-group-outline mb-3">
                                                        <label className="form-label"></label>

                                                        <textarea
                                                            className="form-control shadow-none"
                                                            type="text"
                                                            required="required"
                                                            value={this?.state?.contactAddress}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    contactAddress: e.target.value,
                                                                    colorAddress: "black",
                                                                })
                                                            }
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>


                                            <hr/>

                                            <div className="row" style={{padding: 10}}>
                                                <label
                                                    className="mb-3 h4"
                                                    style={{color: "green"}}
                                                    htmlFor="floatingInputCustom"
                                                >
                                                    Professional Development
                                                </label>
                                                <br/>
                                                <div>
                                                    {this.state.qualifications.map((qual, index) => (
                                                        <div key={index} className="row">
                                                            <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                                                <label className="form-label"
                                                                       style={{color: this.state.colorQual}}>
                                                                    Qualification <span className="text-danger">*</span>
                                                                </label>
                                                                <div className="input-group input-group-outline mb-3">
                                                                    <select
                                                                        className="form-control shadow-none"
                                                                        value={qual.qualification}
                                                                        onChange={e => this.updateQualification(index, 'qualification', e.target.value)}
                                                                    >
                                                                        <option disabled>-- Select qualification --
                                                                        </option>
                                                                        <option value="PHD_DEGREE">PH.D DEGREE</option>
                                                                        <option value="PHD_STUDENT">PH.D STUDENT
                                                                        </option>
                                                                        <option value="MSC_DEGREE">M.SC DEGREE</option>
                                                                        <option value="MSC_STUDENT">M.SC STUDENT
                                                                        </option>
                                                                        <option value="NIFST_REFOST_PRESENTATION">NIFST
                                                                            REFOST / PRESENTATION
                                                                        </option>
                                                                        <option
                                                                            value="INTERNATIONAL_FST_CONFERENCES_PRESENTATION">INTERNATIONAL
                                                                            FST CONFERENCES / PRESENTATION
                                                                        </option>
                                                                        <option value="NICO_FST_TRAINING_WORKSHOP">NICO
                                                                            FST TRAINING WORKSHOP
                                                                        </option>
                                                                        <option value="INTL_FST_CERTIFICATION">INT'L FST
                                                                            CERTIFICATION
                                                                        </option>
                                                                        <option value="WORK_EXPERIENCE">WORK
                                                                            EXPERIENCE
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                                                <label className="form-label"
                                                                       style={{color: this.state.colorQualYear}}>
                                                                    Year Attained <span className="text-danger">*</span>
                                                                </label>
                                                                <div className="input-group input-group-outline mb-3">
                                                                    <input
                                                                        className="form-control shadow-none"
                                                                        type="number"
                                                                        value={qual.year}
                                                                        onChange={e => this.updateQualification(index, 'year', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                                                <input
                                                                    className="form-control shadow-none"
                                                                    type="file"
                                                                    onChange={e => this.handleFileChange(index, e.target.files[0])}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <button className="btn btn-primary"
                                                            onClick={this.addQualification}>Add Qualification
                                                    </button>
                                                </div>


                                                <div className="row" style={{marginTop: 25}}>
                                                    <Form.Floating className="mb-3">
                                                        <input
                                                            class="form-check-input shadow-none"
                                                            type="checkbox"
                                                            id="previousQualification"
                                                            name="US"
                                                            value="US"
                                                            onChange={() => {
                                                            }}
                                                        />{" "}
                                                        show previously filled Qualification(s)
                                                    </Form.Floating>
                                                </div>
                                                <span className="text-danger form-label font-weight-bold">
                            Include any additional qualifications you have
                            obtained this year. This qualification should not be
                            a duplicate of any already submitted
                          </span>
                                                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                                    <label
                                                        style={{color: this.state.colorQual}}
                                                        className="form-label"
                                                    >
                                                        Qualification{" "}
                                                        <span className="text-danger"></span>
                                                    </label>
                                                    <div className="input-group input-group-outline mb-3">
                                                        <label className="form-label"></label>
                                                        <select
                                                            className="form-control shadow-none"
                                                            aria-label="Select"
                                                            value={
                                                                this?.state?.additionQualification?.qualification
                                                            }
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    additionQualification: {
                                                                        ...this.state.additionQualification,
                                                                        qualification: e.target.value,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <option
                                                                // disabled
                                                                selected
                                                            >
                                                                -- Select qualification --
                                                            </option>
                                                            <option selected disabled>
                                                                -- Select category --
                                                            </option>
                                                            <option value="PHD_DEGREE">
                                                                PH.D DEGREE
                                                            </option>
                                                            <option value="PHD_STUDENT">
                                                                PH.D STUDENT
                                                            </option>
                                                            <option value="MSC_DEGREE">
                                                                M.SC DEGREE
                                                            </option>
                                                            <option value="MSC_STUDENT">
                                                                M.SC STUDENT
                                                            </option>
                                                            <option value="NIFST_REFOST_PRESENTATION">
                                                                NIFST REFOST / PRESENTATION
                                                            </option>
                                                            <option value="INTERNATIONAL_FST_CONFERENCES_PRESENTATION">
                                                                INTERNATIONAL FST CONFERENCES / PRESENTATION
                                                            </option>
                                                            <option value="NICO_FST_TRAINING_WORKSHOP">
                                                                NICO FST TRAINING WORKSHOP
                                                            </option>
                                                            <option value="INTL_FST_CERTIFICATION">
                                                                INT'L FST CERTIFICATION
                                                            </option>
                                                            <option value="WORK_EXPERIENCE">
                                                                WORK EXPERIENCE
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                                                    <label
                                                        style={{color: this.state.colorQualYear}}
                                                        className="form-label"
                                                    >
                                                        Year Attained{" "}
                                                        <span className="text-danger"></span>
                                                    </label>
                                                    <div className="input-group input-group-outline mb-3">
                                                        <label className="form-label"></label>
                                                        <input
                                                            className="form-control shadow-none"
                                                            type="number"
                                                            required="required"
                                                            value={this?.state?.additionQualification?.year}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    additionQualification: {
                                                                        ...this?.state?.additionQualification,
                                                                        year: e.target.value,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-lg-12 col-md-6 mb-3">
                                                    <label
                                                        className=" mb-2"
                                                        style={{color: this.state.colorPassport}}
                                                        htmlFor="floatingInputCustom"
                                                    >
                                                        Upload Your Qualification Image
                                                    </label>

                                                    <div className="col-sm-6 col-lg-4 col-md-4 mb-3">
                                                        <input
                                                            className="form-control shadow-none"
                                                            type="file"
                                                            name="certificateImage"
                                                            defaultValue={
                                                                this.state?.additionQualification?.certificateImage
                                                            }
                                                            required="required"
                                                            onChange={this.handleFileChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <hr/>
                                            <label
                                                className="mb-3 h4"
                                                style={{color: "green"}}
                                                htmlFor="floatingInputCustom"
                                            >
                                                {`Place of work   `} <small className="text-danger">(Fill if currently
                                                employed)</small>
                                            </label>

                                            {employed && (<>
                                                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                    <label
                                                        style={{color: this.state.colorOrgName}}
                                                        className="form-label"
                                                    >
                                                        Name of Organization/Institution{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="input-group input-group-outline mb-3">
                                                        <label className="form-label"></label>
                                                        <input
                                                            className="form-control shadow-none"
                                                            type="text"
                                                            required="required"
                                                            value={this?.state?.organizationName}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    organizationName: e.target.value,
                                                                    colorOrgName: "black",
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                    <label
                                                        style={{color: this.state.colorOrgPosition}}
                                                        className="form-label"
                                                    >
                                                        Position <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="input-group input-group-outline mb-3">
                                                        <label className="form-label"></label>
                                                        <input
                                                            className="form-control shadow-none"
                                                            type="text"
                                                            required="required"
                                                            value={this?.state?.organizationPosition}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    organizationPosition: e.target.value,
                                                                    colorOrgPosition: "black",
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                    <label
                                                        style={{color: this.state.colorOrgAddress}}
                                                        className="form-label"
                                                    >
                                                        Address <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="input-group input-group-outline mb-3">
                                                        <label className="form-label"></label>
                                                        <input
                                                            className="form-control shadow-none"
                                                            type="text"
                                                            required="required"
                                                            value={this?.state?.organizationAddress}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    organizationAddress: e.target.value,
                                                                    colorOrgAddress: "black",
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                    <label
                                                        style={{color: this.state.colorOrgPhone}}
                                                        className="form-label"
                                                    >
                                                        Organization Telephone{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="input-group input-group-outline mb-3">
                                                        <label className="form-label"></label>
                                                        <input
                                                            className="form-control shadow-none"
                                                            type="number"
                                                            required="required"
                                                            value={this?.state?.organizationTelephone}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    organizationTelephone: e.target.value,
                                                                    colorOrgPhone: "black",
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                    <label
                                                        style={{color: this.state.colorOrgEmail}}
                                                        className="form-label"
                                                    >
                                                        Organization Email{" "}
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <div className="input-group input-group-outline mb-3">
                                                        <label className="form-label"></label>
                                                        <input
                                                            className="form-control shadow-none"
                                                            type="text"
                                                            required="required"
                                                            value={this?.state?.organizationEmail}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    organizationEmail: e.target.value,
                                                                    colorOrgEmail: "black",
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </>)}

                                            <div className="row" style={{marginTop: 25}}>
                                                <Form.Floating className="mb-3">
                                                    <input
                                                        class="form-check-input shadow-none"
                                                        type="checkbox"
                                                        id="checkBoxUS"
                                                        name="US"
                                                        value="US"
                                                        onChange={this.handleEmployedCheck}
                                                    />{"  "}
                                                    Are you currently employed?
                                                </Form.Floating>
                                            </div>

                                            <hr/>

                                            <label
                                                className="h4"
                                                htmlFor="floatingInputCustom"
                                                style={{color: "green"}}
                                            >
                                                Practice Category
                                            </label>
                                            <br/>
                                            <br/>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                <label
                                                    style={{color: this.state.practiceCat}}
                                                    className="form-label"
                                                >
                                                    Practice Category{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="input-group input-group-outline mb-3">
                                                    <label className="form-label"></label>
                                                    <select
                                                        className="form-control shadow-none"
                                                        aria-label="Select"
                                                        value={this?.state?.practiceCategory}
                                                        onChange={this.handlePracticeChange}
                                                        style={{color: this.state.colorPraCat}}
                                                    >
                                                        <option selected disabled>
                                                            -- Select category --
                                                        </option>
                                                        <option value="ACADEMIC/RESEARCH">
                                                            ACADEMIC/RESEARCH
                                                        </option>
                                                        <option value="GOVERNMENT/REGULATORY">
                                                            GOVERNMENT/REGULATORY
                                                        </option>
                                                        <option value="INDUSTRY">INDUSTRY</option>
                                                        <option value="PRIVATE PRACTICE">
                                                            PRIVATE PRACTICE
                                                        </option>
                                                        <option value="NGO">NGO</option>
                                                        <option value="INTERNATIONAL DEVELOPMENT">
                                                            INTERNATIONAL DEVELOPMENT
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>


                                            <hr/>

                                            <div className="row" style={{marginTop: 25}}>
                                                <Form.Floating className="mb-3">
                                                    <input
                                                        class="form-check-input shadow-none"
                                                        type="checkbox"
                                                        id="checkBoxUS"
                                                        name="US"
                                                        value="US"
                                                        onChange={this.handleCheckChange}
                                                    />{" "}
                                                    I hereby certify that I am not a registered and
                                                    practicing member of an allied profession and the
                                                    particulars furnished herein are true
                                                </Form.Floating>
                                            </div>

                                            <div
                                                className="text-center"
                                                style={{
                                                    margin: "auto",
                                                    width: "100%",
                                                    marginTop: 45,
                                                    marginBottom: "",
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    disabled={this.state.disabled}
                                                    style={{
                                                        alignSelf: "center",
                                                        width: "100%",
                                                        backgroundColor: "#003314",
                                                    }}
                                                    className="btn btn-success btn-lg"
                                                    onClick={(e) => this.checkValidation(e)}
                                                >
                                                    {loading && (
                                                        <Spinner
                                                            animation="border"
                                                            variant="light"
                                                            size="sm"
                                                        />
                                                    )}
                                                    <span className="font-weight-bold">
                                                                   {" SUBMIT APPLICATION AND PROCEED TO PAYMENT"}
                                                                  </span>
                                                </button>
                                            </div>
                                        </form>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </NewDashboardLayout>
        );
    }
}

export default NewRegisterLicence
